import React from "react";
import Adedayo from "../../../assets/images/section/TeamsPage/teamphoto/adedayo.png";
import Dolapo from "../../../assets/images/section/TeamsPage/teamphoto/dolapo.png";
import Emmanuel from "../../../assets/images/section/TeamsPage/teamphoto/emmanuel.png";
import Ibrahim from "../../../assets/images/section/TeamsPage/teamphoto/Ibrahim.png";
import Kingsley from "../../../assets/images/section/TeamsPage/teamphoto/Kingsley.png";
import Omoshewa from "../../../assets/images/section/TeamsPage/teamphoto/Omoshewa.png";
import Samson from "../../../assets/images/section/TeamsPage/teamphoto/samson.png";
import Felix from "../../../assets/images/section/TeamsPage/teamphoto/felix.png";
import Abraham from "../../../assets/images/section/TeamsPage/teamphoto/abraham.png";
import Akintade from "../../../assets/images/section/TeamsPage/teamphoto/akintade.png";
export default [
  {
    id: 1,
    imge: Felix,
    name: "Felix Awoyale",
    role: "Engineering",
    description: (
      <p>
        {" "}
        Felix is a front-end developer, currently working with Hashtag Digital
        services. He previously worked at Sophia ERP where he worked as a
        software engineer. He is also a graphics designer, Creative director &
        Videographer.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        He loves to play games and listen to music a lot{" "}
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/awoyale-felix-5490ab141/",
    },
  },
  {
    id: 2,
    imge: Omoshewa,
    name: "Omoshewa Olabanji",
    role: "Product",
    description: (
      <p>
        I am Omoshewa Olabanji, a graduate of University of Nigeria (Nsukka). I
        work as a Product manager for Hashtag digital service limited and also
        as an Administrative officer.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        I am a selfless, cheerful and disciplined person.{" "}
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/omoshewa-olabanji-206bb5228/",
    },
  },
  {
    id: 3,
    imge: Samson,
    name: "Samson Samuels",
    role: "Cofounder/Product Lead",
    description: (
      <p>
        Samson is a highly-skilled, result-oriented software engineer and UI
        designer, and he's more passionate about the visual aspect of everything
        he comes across whether digital or not. He has pretty years of
        experience in building blockchain/cryptocurrency, fintech, telehealth
        products, and also consults for several organizations. He is currently
        the Co-founder and Product lead at Hashtag Digital Services Ltd.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        Samson is a professional photographer, a great teacher, and a lover of
        cats and dogs{" "}
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/sammieneutron/",
    },
  },
  {
    id: 4,
    imge: Abraham,
    name: "Abraham Ameh",
    role: "Engineering",
    description: (
      <p>
        Result and detailed oriented engineer dedicated to designing, creating
        and optimizing interactive, user-friendly, and feature-rich software
        solutions, I love training people of all ages in emerging technology. my
        knowledge are about software development, product design and startup
        ecosystem.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        I really enjoy playing Video Games, Watching Movies and participating in
        Startups and Tech based events, Hackathons and Hangout{" "}
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/ameh-abraham-2553951bb/",
    },
  },
  {
    id: 5,
    imge: Dolapo,
    name: "Dolapo Damilola",
    role: "Content Creator",
    description: (
      <p>
        Dolapo is a social media manager and content writer. She is a prolific
        writer and content creator with over 2years of experience. She's also a
        Film and stage producer/director.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        Loves to feed people
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/dolapo-bankole-27a505170",
    },
  },
  // {
  //     id: 6,
  //     imge: Adedayo,
  //     name: 'Adedayo Ilesanmi',
  //     role: 'Engineering',
  //     description:
  //         <p>Dayo is currently a student of the University of Lagos who has decided to
  //         start her Front End Development journey in Hashtag as an intern. <br/><br/>

  //         <span style={{color: '#2E2F6E', fontWeight: '700'}}>Fun Fact :</span> Certified Foodie who binge watches for a living </p>,
  //     on: true,
  //     social: {
  //         Instagram: '',
  //         Twitter: '',
  //         LinkedIn: 'hhttps://www.linkedin.com/in/adedayo-ilesanmi-a574571a1'
  //     }
  // },
  {
    id: 6,
    imge: Emmanuel,
    name: "Emmanuel Adubi",
    role: "Engineering",
    description: (
      <p>
        I'm a passionate python programmer. I'm also a Python Django backend
        developer. I love doing research and constantly learning about new
        things.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        I love excellent music, good food and good sleep for few hours.
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/emmanueladubi/",
    },
  },
  {
    id: 7,
    imge: Akintade,
    name: "Akintade Britto",
    role: "Founder/Tech Lead",
    description: (
      <p>
        Akintade is a lover of entrepreneurship and engineering - specifically,
        the parts of digital technology and problem-solving. Here is a
        passionate and experienced IT professional who has worked in industries
        including Fintech, Ed-tech, Consultancy, in different capacities as
        Software Engineer, DevOps/SRE Engineer and Programming Instructor. He's
        currently the Founder and Technical lead at Hashtag Digital Services
        Ltd. <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        N/A{" "}
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/akintade-britto/",
    },
  },
  {
    id: 8,
    imge: Kingsley,
    name: "Kingsley Mbilitem",
    role: "Product Designer",
    description: (
      <p>
        Kingsley Osita Mbilitem, Senior Product Designer/UI/UX designer with 10
        years experience in creative directing and designing. Holds a Bachelor
        of Art Degree from the University Of Benin where he studied Graphic
        Design. He has worked as a Senior Creative Designer with prestigious
        companies like Polo Luxury, Swarovski, Montblanc among others. Hails
        from Imo State end currently lives in Lagos. <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        N/A
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/kingsleymbilitem/",
    },
  },
  {
    id: 9,
    imge: Ibrahim,
    name: "Ibrahim Muhammed",
    role: "Graphics designer",
    description: (
      <p>
        Ibrahim is a graphic designer and a vector illustrator at Hashtag
        Digital Services Ltd. He's a passionate creative who likes to convey
        visual communication in a fun and aesthetically pleasing manner. He's
        also a content creator and a social media manager.
        <br />
        <br />
        <span style={{ color: "#2E2F6E", fontWeight: "700" }}>
          Fun Fact :
        </span>{" "}
        I'm a dancer, enjoy football games and I am a cat lover
      </p>
    ),
    on: true,
    social: {
      Instagram: "",
      Twitter: "",
      LinkedIn: "https://www.linkedin.com/in/ibrahim-muhammed-9b52ab178",
    },
  },
];
